import { SHARE_CODE_SEARCH_KEY, SHARE_CODE_STORE_KEY } from '../constants';
import { getEnv, ENV } from './getEnv';

export default function (search: string) {
  const currentEnv = getEnv();
  const query = new URLSearchParams(search);
  const shareCode = query.get(SHARE_CODE_SEARCH_KEY);
  if (!shareCode) return;

  localStorage.setItem(SHARE_CODE_STORE_KEY, shareCode);

  // 在 iframe 中时就不打开了，防止死循环
  if (query.get('iframe') === '1') return;

  const value = `/?${SHARE_CODE_SEARCH_KEY}=${shareCode}&iframe=1`;

  if (currentEnv === ENV.PROD) {
    createIframe(`https://saturncloud.com.cn${value}`);
  }
  if (currentEnv === ENV.WEBSITE) {
    createIframe(`https://xxyy.co${value}`);
  }
  // createIframe(`http://localhost:9528${value}`);
}

function createIframe(src: string) {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = src;
  document.body.append(iframe);
  console.dir(iframe);
  iframe.onload = function () {
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 10000);
  };
}
