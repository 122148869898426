const React = require('react');
const GlobalLayout = require('./src/components/Layout/Global').default;
// const NoticeModal = require('./src/components/NoticeModal').default;
const package = require('./package.json');
console.log('v' + package.version);

// Logs when the client route changes
// exports.onRouteUpdate = ({ location, prevLocation }) => {
//   console.log('new pathname', location.pathname);
//   console.log('old pathname', prevLocation ? prevLocation.pathname : null);
// };

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return (
    <>
      {/* <NoticeModal /> */}
      <GlobalLayout {...props}>{element}</GlobalLayout>
    </>
  );
};
