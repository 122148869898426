import { PageProps, Script } from 'gatsby';
import React, { useEffect } from 'react';
import applyShareCode from '../../utils/applyShareCode';

export default function GlobalLayout({ children, location }: PageProps) {
  useEffect(() => {
    /** 添加邀请注册逻辑 */
    applyShareCode(location.search);
  }, []);
  return <>{children}</>;
}
